import { FunctionComponent, MouseEvent } from "react";

import {
  CenteredBox,
  FontIcon,
  FontSizes,
  Link,
  useTheme
} from "@bps/fluent-ui";

export const AuthorizationErrorBox: FunctionComponent = () => {
  const theme = useTheme();
  const onLoginClick = (event: MouseEvent<HTMLLinkElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };
  return (
    <CenteredBox>
      <FontIcon
        iconName="BpLogo"
        styles={{
          root: { color: theme.palette.black, fontSize: FontSizes.size24 }
        }}
      />
      <h2>403</h2>
      <p>
        You do not have access to Premier Online products and services. <br />
        For access, please contact your practice administrator. Alternatively,
        login as a different user.
      </p>
      <p>
        Alternatively,{" "}
        <Link onClick={onLoginClick}>login as a different user.</Link>
      </p>
    </CenteredBox>
  );
};
